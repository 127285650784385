import { registerHelper } from "./Handlebars-helper";
import Handlebars from "handlebars";
import moment from "moment";

// Get URL parameters as an instance of URLSearchParams
export const getUrlParams = async(key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const base64EncodeUnicode_HTML = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  var utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );
  return btoa(utf8Bytes);
};

export const base64DecodeUnicode_HTML = (str) => {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  const percentEncodedStr = atob(str)
    .split("")
    .map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("");

  return decodeURIComponent(percentEncodedStr);
};

export const updateHandlebarsTemp = async (element, templateFunc, data) => {
  // Compile Handlebars template
  const compiledTemplate = Handlebars.compile(templateFunc);
  registerHelper();
  const html = compiledTemplate(data);
  element.innerHTML = html;
};

// Function to convert time string to minutes
export const convertTimeToMinutes = (timeString, split = /h|m/) => {
  const [hoursStr, minutesStr] = timeString
    .split(split)
    .map((str) => str.trim());
  const hours = parseInt(hoursStr) || 0;
  const minutes = parseInt(minutesStr) || 0;
  // const totalMinutes = (type == 'Service' || type == 'Appointment')?  hours * 60 + minutes : hoursStr;
  return hoursStr;
};

// Function to convert timestamp to minutes
export const convertUnixTimestamp = (timestamp, format) => {
  return moment.unix(timestamp).format(format);
};

// export const momentDateTimeFormat = (data, format) => {
//   // return moment(data).format(format);
//   // const parsedDate = isTimestamp === "timestamp" ? moment.unix(data) : moment(data);
//   return moment(new Date(data)).format(format);
// };

export const findSuperParentWithClass = (element, className) => {
  // Check if the element itself has the class
  if (element.classList.contains(className)) {
    return element; // Return the element itself
  }
  var parent = element.parentNode;
  while (parent !== null) {
    if (parent.classList.contains(className)) {
      return parent; // Found the super parent with the class
    }
    parent = parent.parentNode; // Move to the next parent node
  }
  return null; // Super parent with the class not found
};

export const updateSelectedClass = (classname, addTo) => {
  const element = document.querySelectorAll(classname);
  // Remove the 'selected' class from all slot
  element.forEach((item) => {
    item.classList.remove("APO_selected");
  });
  // Add the 'selected' class to the clicked slot
  addTo.classList.add("APO_selected");
};

export const hideLoaderAfterPageLoad = () => {
  const insertData = document.getElementById("insertData");
  insertData.style.display = "";
  setSectionLoader(false, '');
}

export const setSectionLoader = (isLoading, targetElement) => {
  const APO__screenmask = document.querySelector(".APO__screenmask");
  if (!targetElement && !APO__screenmask) return; // Ensure targetElement is valid
  if (!isLoading) {
    APO__screenmask.remove();
  }
  else {
    const loaderHtml = `<div class="APO__screenmask show transition_class flex justify-center items-center bg-white"><span class="APO__loader APO__spin h-16 w-16"></span></div>`;
    document.querySelector(targetElement).insertAdjacentHTML("beforeend", loaderHtml);
  }
}

export const setBtnLoader = (isLoading, targetElement, size = [16]) => {
  if (!targetElement) return; // Ensure targetElement is valid
  
  if (!isLoading) {
    const originalContent = targetElement.getAttribute("data-original-content");
    if (originalContent) {
      targetElement.innerHTML = originalContent;
    }
    targetElement.removeAttribute("data-original-content");
    targetElement.disabled = false; // Re-enable the button
  } else {
    if (!targetElement.getAttribute("data-original-content")) {
      targetElement.setAttribute("data-original-content", targetElement.innerHTML);
    }
    const loaderHtml = `<span class="APO__loader APO__spin block mx-auto" style="width:${size[0]}px;"></span>`;
    targetElement.innerHTML = loaderHtml;
    targetElement.disabled = true; // Disable the button to prevent multiple clicks
  }
};

