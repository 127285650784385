import { GetAPI_deleteAPI_method } from "./Api";

// fetch SEO data from API
export const fetchSEOData = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("SeoSettings", "GET");
    if (response.status == 200 && response.data) {
      updateSEOTags(response.data); // Update SEO with fetched data
    }
  } catch (error) {
    console.error("Error fetching SEO data:", error);
  }
};

// update SEO tags
const updateSEOTags = (data) => {
  // Update page title
  document.title = data.title;

  // Update or add meta description
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", data.description);
  }

  // Update or add meta keywords
  const keywords = data.keyword.map((kw) => kw.keys.trim()).join(", ");
  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", keywords);
  } else {
    metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = keywords;
    document.head.insertBefore(metaKeywords, document.head.children[3]); // Insert after description meta
  }
};
