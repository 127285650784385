import { APOData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { updateHandlebarsTemp } from "./CommonMethod";
import { fetchSEOData } from "./SeoSettings";
import moment from "moment";

export const themeTypeSetting = async () => {
  const { isTabs } = templateJSON;

  // Initialize jQuery UI tabs with custom active class handling
  if (isTabs) {
    $(".TabWidget .widge-TabContant").tabs({
      activate: function (event, ui) {
        ui.oldTab.removeClass("active");
        ui.newTab.addClass("active");
      },
    });
  }
};

export const templateSetting = async () => {
  try {
    await updateOpeningHours(); // Update opening hours
    await updateContactData(); // Update contact details
    await CookieTermsAndPolicy(); // Update Cookie, Term And Policy
    await defaultTemplateStyle(); // Apply default template style
    await fetchSEOData(); // Apply Seo Settings
  } catch (error) {
    console.error("Error in updating template settings:", error);
  }
};


const updateOpeningHours = async () => {
  try {
    const {businessHoursList} = APOData.onboardingData
    const openingH = document.querySelector(".Bhours-list");
    const template = document.getElementById("template_Bhours").innerHTML;

    // Compile Handlebars template method
    await updateHandlebarsTemp(openingH, template, {businessHoursList, currentDay: moment(new Date()).format("dddd")});
  } catch (error) {
    console.error("Error updating opening hours:", error);
  }
};

const updateContactData = async () => {
  try {
    const {businessAddressList} = APOData.onboardingData
    if(!!businessAddressList || businessAddressList.length) {
      const addressSection = document.querySelector(".Baddress-detail");
      const template = document.getElementById("template_Baddress").innerHTML;

      // Compile Handlebars template method
      await updateHandlebarsTemp(
        addressSection,
        template,
        {businessAddressList: businessAddressList}
      );
      
    }
  } catch (error) {
    console.error("Error updating contact data:", error);
  }
};

const CookieTermsAndPolicy = async () => {
  try {
    // Fetch Privacy Policy, Terms, and Cookies data
    const response = await GetAPI_deleteAPI_method(
      "CookieTermsAndPolicy",
      "GET"
    );
    const { cookies, policy, terms } = response.data;

    const updateSection = async (sectionSelector, templateId, data, isEnabled) => {
      const sectionElement = document.querySelector(sectionSelector);
      sectionElement.style.display = isEnabled ? "" : "none";
      if (isEnabled) {
        const templateHTML = document.getElementById(templateId).innerHTML;
        await updateHandlebarsTemp(sectionElement, templateHTML, data);
    
        // Add custom class based on the section's dataset value if present
        if (sectionElement.dataset.class) {
          sectionElement.classList.add(sectionElement.dataset.class);
        }
      }
    }

    // Update Privacy Policy section
    await updateSection(".PrivacyPolicy-section", "template_PrivacyPolicy", policy, policy.isEnablePolicy);

    // Update Terms and Conditions section
    await updateSection(".Terms-section", "template_Terms", terms, terms.isEnableTerms);

    // Update Cookies section
    await updateSection(".Cookies-Section", "template_Cookies", cookies, cookies.isEnableCookies);

    // Remove entire section if all policies are disabled
    if (!policy.isEnablePolicy && !terms.isEnableTerms && !cookies.isEnableCookies) {
      const section = document.querySelector(`[data-section="CookieTermsAndPolicy"]`);
      if (section) {
        section.remove();
      }
    }
  } catch (error) {
    console.error("Error loading Privacy Policy or Terms data:", error);
  }
};

export const fetchBookingRules = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("BookingRules", "GET");
    APOData.Bookingrules = response.data;
    await applyBookingRulesToDomElements();
  } catch (error) {
    console.error("Error fetching booking rules:", error);
  }
};

export const filterBookingRule = async (allowedKeys) => {
  const { bookingRulesLists } = APOData.Bookingrules;

  if (allowedKeys.length > 1) {
    return bookingRulesLists.filter((rule) =>
      allowedKeys.includes(rule.keyName)
    );
  } else {
    return bookingRulesLists.find((rule) => rule.keyName === allowedKeys[0]);
  }
};

const applyBookingRulesToDomElements = async () => {
  try {
    // Rules related to removing DOM elements
    const removeDomElements = {
      removeIfTrue: {
        IsHideAppointUsBranding: "[data-rule=IsHideAppointUsBranding]",
      },
      removeIfFalse: {
        IsUserReviewEnabled: "[data-rule=IsUserReviewEnabled]",
        IsHeaderAndLogoEnabled: "[data-rule=IsHeaderAndLogoEnabled]",
        IsAboutUsVisible: "[data-rule=IsAboutUsVisible]",  
        IsBookAppointmentVisible: "[data-rule=IsBookAppointmentVisible]",  
      },
    };

    // Iterate over the keys of removeDomElements
    Object.entries(removeDomElements).forEach(async ([conditionType, elementsToRemove]) => {
        // Fetch and process the rules for each condition (removeIfTrue or removeIfFalse)
        for (const ruleName in elementsToRemove) {
          const selector = elementsToRemove[ruleName];

          // Fetch the rule status for each key (assuming filterBookingRule returns a single rule)
          const DomElementsRule = await filterBookingRule(ruleName);
          if (DomElementsRule && DomElementsRule.length > 0) {
            const rule = DomElementsRule[0];

            // Determine whether to remove the element based on the rule and condition type
            const shouldRemove =
              (conditionType === "removeIfTrue" && rule.isSelected) ||
              (conditionType === "removeIfFalse" && !rule.isSelected);

            if (shouldRemove && selector) {
              const elements = document.querySelectorAll(selector);
              elements.forEach((element) => element.remove());
            }
          }
        }
      });

  } catch (error) {
    console.error("Error applying Dom elements booking rules:", error);
  }
};

const defaultTemplateStyle = async () => {
  try {

    const { brandBannerUrl, brandLogoUrl } = APOData.onboardingData;
    
    const brandBanner = document.querySelector(".BrandBanner");
    const brandLogo = document.querySelector(".BrandLogo");
    if (brandBannerUrl && brandBanner) {
      const whereApply = brandBanner.dataset.bg;

      brandBanner.style.backgroundImage = whereApply === "rowBG" ? `url(${brandBannerUrl})` : "none";
      brandBanner.querySelector(".row-content").style.backgroundImage = whereApply === "rowBG" ? "none" : `url(${brandBannerUrl})`;
    }
    if (brandLogoUrl && brandLogo) {
      brandLogo.querySelector("img").src = brandLogoUrl;
    }

  } catch (error) {
    console.error("Error fetching template style:", error);
  }
};
