import Navigo from "navigo";
import { APO__chooseService, APO__UserDetails } from "./Serivce";
import { appointmentLayout } from "./ServiceFilterHtml";
import { APOData, templateJSON } from "./BuilderProps";
import { serviceQueryParams } from "./Serivceconfig";
import { findBookingFormListeners } from "./BookedAppointmentsList";
import { initializeReviews } from "./Review";
import { displayTeamList, hideTeamSection } from "./Teams";
import { bindLocationLayout } from "./MultiLocation";
import { fetchStaffByLocation } from "./SingleStaff";

export const router = new Navigo("/", { hash: true });

export const PageRouting = () => {
  router
    .on({
      "/": async () => handleHomepage(),

      "/:name": async () => handleHomepage(),

      "/review": (params) => handleReviewPage(params),

      "/bookings/type/upcoming": async (params) => handleUpcomingBookings(params),

      "/book": async (params) => handleBookingPage(params),

      "/book/step=:step*": async (params) => handleBookingStep(params),
    })
    .resolve();
};
router.updatePageLinks();

const handleHomepage = async () => {
  handleNavigation("Homepage");
  if (window.location.hash !== "#/book") await setupAppointmentOrLocation();
};

const handleReviewPage = (params) => {
  templateJSON.templatePageUnder === "tabs"
    ? activateRouterTab(params.route.name)
    : handleNavigation("Reviewpage");
  initializeReviews();
};

const handleUpcomingBookings = async (params) => {
  //clear booked appointment list
  const BookingWrapper = document.querySelector(".Booking .BK-wrapper");
  if (BookingWrapper) BookingWrapper.innerHTML = "";

  templateJSON.templatePageUnder === "tabs"
    ? activateRouterTab(params.route.name)
    : handleNavigation("Bookingpage");

  // find booking from event Listeners
  findBookingFormListeners();
};

const handleBookingPage = async (params) => {
  const { APOtype, slugType, APO_selectedData, locationList } = APOData;
  const { steps } = templateJSON.StyleObj["Service"];

  templateJSON.templatePageUnder === "tabs"
  ? activateRouterTab(params.route.name)
  : handleNavigation("Homepage");

  scrollToServiceWidget();

  APO_selectedData.serviceActiveStep = steps[0].name;

  const isHideTeam = locationList.length === 1 || slugType === "team" ? false : true;
  hideTeamSection(isHideTeam);
  
  if (locationList.length > 1) {
    await bindLocationLayout();
  } else {
    await appointmentLayout(APOData[APOtype]);
    await displayTeamList();
  }
};

const handleBookingStep = async (params) => {
  const { data } = params;

  // Destructure necessary data from APOData and templateJSON
  const { Bookingrules, slugType } = APOData;

  // Check if the "IsServicesVisible" rule is selected
  const isBookingVisible = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsBookAppointmentVisible"
  )?.isSelected;

  if (!isBookingVisible) {
    return router.navigate("/");
  }

  templateJSON.templatePageUnder === "tabs"
  ? activateRouterTab("book")
  : handleNavigation("Homepage");

  const stepsData = await serviceQueryParams(`step=${data.step}`);
  handleStepNavigation(stepsData.step, slugType);
};

const setupAppointmentOrLocation = async () => {
  // Destructure required data from APOData
  const { slugType, APO_selectedData, APOtype, locationList } = APOData;
  const { steps } = templateJSON.StyleObj["Service"];

  APO_selectedData.serviceActiveStep = steps[0].name;
  const isHideTeam = locationList.length === 1 || slugType === "team" ? false : true;
  hideTeamSection(isHideTeam);
  
  if (locationList.length > 1) {
    await bindLocationLayout();
  } else {
    await appointmentLayout(APOData[APOtype]);
    await displayTeamList();
  }
};

const handleNavigation = (pageClass) => {
  // Navigate to the specified page
  navigateToPage(pageClass);

  // Function to update the active class on the menu items
  updateNavActiveClass();

  // Hide the team section if necessary (pass a parameter to control visibility)
  // hideTeamSection(false);
};

// Navigate to the specified page
const navigateToPage = (pageClass) => {
  const allPages = document.querySelectorAll(".page-wrapper");

  allPages.forEach((page) => {
    const isCurrentPage = page.classList.contains(pageClass);
    page.classList.toggle("hidden", !isCurrentPage);

    if (page.classList.contains("Navbar") || page.classList.contains("Footer-section")) {
      page.classList.remove("hidden");
    }
  });
};

// Function to update the active class on the menu items
const updateNavActiveClass = () => {
  const navLinks = document.querySelectorAll(".nav-link");

  navLinks.forEach((link) => {
    const href =
      link.getAttribute("href") === "#" ? "" : link.getAttribute("href");
    link.classList.toggle("active", window.location.hash === href);
  });
};

const scrollToServiceWidget = () => {
  const section = document.querySelector(".Service[builder-element]");
  if (section) {
    setTimeout(() => {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  }
};

const handleStepNavigation = async (step, slugType) => {
  // Destructure required data from APOData
  const { APOtype } = APOData;
  
  // Hide the team section if necessary (pass a parameter to control visibility)
    const isClassOrGlobalEvents = APOtype === "GlobalEvents" || APOtype === "Class";
  const isHideTeam = isClassOrGlobalEvents || step !== "servicelist";
  hideTeamSection(isHideTeam);


  switch (step) {
    case "servicelist":
      if (slugType !== "team" && !isClassOrGlobalEvents) {
        await fetchStaffByLocation();
        await displayTeamList();
      }
      await appointmentLayout(APOData[APOtype]);
      break;
    case "stafflist":
    case "datetime":
      await APO__chooseService();
      break;
    case "userdetails":
      await APO__UserDetails();
      break;
    default:
      console.warn("Unknown step:", step);
  }

};

export const activateRouterTab = (routeName) => {
  const routerTab = document.querySelector(
    `.TabWidget .widge-TabContant li[data-navigo="#/${routeName}"]`
  );

  // Hide the team section if necessary (pass a parameter to control visibility)
  // hideTeamSection(false);

  // Get the parent <ul> element
  const ulElement = routerTab.parentElement;

  // Convert the children of the <ul> to an array and find the index
  const liElements = Array.from(ulElement.children);
  const index = liElements.indexOf(routerTab);

  if (!JSON.parse(routerTab.getAttribute("aria-selected"))) {
    $(ulElement.parentElement).tabs({ active: index });
  }
};

window.tabclickrouter = (event, element) => {
  const navigoLink = element.dataset.navigo;
  const path = navigoLink.split("#");
  if (path[1] === "/book") {
    const { APO_selectedData, slugType } = APOData;
    const { steps } = templateJSON.StyleObj["Service"];
    APO_selectedData.serviceActiveStep = steps[0].name;
  }
  router.navigate(path[1]);
};

export const bookingConfirmation = (bookingData, type) => {
  router.navigate(
    `/book?step=confirmation&type=${type}&confirmed=${bookingData.id}`
  );
};
