import { APOData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { appointmentTypeList } from "./ServiceFilterHtml";
// import "./Serivce";
import { templateSetting } from "./TemplateSettings";
import { PageRouting } from "./Routes";
import { get__ServiceEventClass } from "./Serivce";
import { companySpecificInformation } from "./SingleInformation";
import { base64EncodeUnicode_HTML } from "./CommonMethod";

export const OnboardingData = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("Onboarding", "GET");
    const { data } = response;
    APOData.onboardingData = data; // service List

    // Company specific information detail
    await companySpecificInformation();
    await appointmentTypeList(data);
    await get__ServiceEventClass();
    await templateSetting(); // template setting's

    // if (!window.location.hash) {
    //   await appointmentLayout(APOData[APOData.APOtype], "Service");
    // }

    PageRouting();
  } catch (error) {
    console.error("Error during onboarding data processing:", error);
  }
};

export const APO__DataUpdate = async (data, type) => {
  const { userCurrencySymbol } = APOData.onboardingData;
  return data.map((item) => ({
    ...item,
    currencySymbol: userCurrencySymbol,
    APOtype: type,
  }));
};
