import { APOData } from "../BuilderProps";
import moment from "moment";
import { PostAPI_PutAPI_method } from "../Api";
import {
  convertTimeToMinutes,
  convertUnixTimestamp,
  handleErrorWithRetry,
  updateHandlebarsTemp,
} from "../CommonMethod";
import { template_meetingConfirmation, template_UserD_placeholderLoading } from "./MeetingHandlebars-template";
import { filterBookingRule } from "../ThemeOnLoadSettings";
import { businessAddressJSON } from "../Handlebars-helper";

export const meeting_Bookappointment = async () => {
  try {
    // Select the meeting content element
    const insertTemplate = document.querySelector(".TM-booking_content");
    await updateHandlebarsTemp(insertTemplate, template_UserD_placeholderLoading(), "");

    const { userDetail, APO_selectedData } = APOData;

    const appointmentButtonKeys = ["IsBookAppointmentButton"];
    const IsBookAppointmentButton = await filterBookingRule(appointmentButtonKeys);

    // Create customer record
    const customerResponse = await PostAPI_PutAPI_method(
      "Customer",
      "POST",
      userDetail
    );

    if (!customerResponse || customerResponse.status !== 200) {
      throw new Error("Failed to create customer.");
    }

    // Prepare appointment details
    const appointmentsObj = prepareMeeting_AppointmentObject(
      customerResponse.data.id
    );

    // Book the appointment
    const appointmentResponse = await PostAPI_PutAPI_method(
      "Appointments",
      "POST",
      appointmentsObj
    );
    // const appointmentResponse = {status: 500} appointmentsObj

    if (appointmentResponse.status === 200) {
      // Prepare data for the template
      const templateData = {
        IsBookAppointmentButton,
        bookingStatus: appointmentResponse.data,
        convertedUserTimeSlot: APO_selectedData.convertedUserTimeSlot,
        appointmentsObj,
        userDetail
      };

      await updateHandlebarsTemp(insertTemplate, template_meetingConfirmation(), templateData);

      APO_selectedData.date = null;
      APO_selectedData.convertedUserTimeSlot = null;
    } else {
      // Update UI with retry option
      const errorMessage = {
        errorTitle: "Oups! Something went wrong!",
        errordescription: "An error occurred: Unable to book the appointment."
      }
      handleErrorWithRetry(
        true,
        insertTemplate,
        errorMessage,
        meeting_Bookappointment
      );
      throw new Error(errorMessage.errordescription);
    }
  } catch (error) {
    console.error("Booking error:", error);
  }
};

const prepareMeeting_AppointmentObject = (customerId) => {
  const { userDetail, APO_selectedData, AllcustomerID, matchedTime, businessTimeZone, guestEmails } = APOData;
  const { appointmentJSON, staffJSON, timeslot } = APO_selectedData;

  const {name, emailAddress, text} = userDetail

  //meeting address
  const inPersonAddress = businessAddressJSON(staffJSON.staffLocation[0]);

  // Initialize appointment date strings
  let startDateStr = convertUnixTimestamp(timeslot, "YYYY-MM-DDTHH:mm:ssZ");

  const totalDuration = convertTimeToMinutes(appointmentJSON.duration);
  let addDuration = moment.unix(timeslot).add(totalDuration, "minutes");
  let endDateateStr = convertUnixTimestamp(
    addDuration.unix(),
    "YYYY-MM-DDTHH:mm:ssZ"
  ); // update endDateate

  // Prepare appointment object
  return {
    title: `New Meeting with ${staffJSON.name}`,
    text: text ? text : `New Meeting with ${staffJSON.name}`,
    color: '#ffffff',
    startDate: startDateStr,
    endDate: endDateateStr,
    timeZone: businessTimeZone,
    customerList: [
      {
        id: customerId,
        name: name,
        emailAddress: emailAddress,
        image: "",
      },
    ],
    guestList: guestEmails ? guestEmails : [],
    listId: AllcustomerID,
    eEventType: "Event",
    appointmentType: "Conference",
    eNotificationType: "Email",
    staffId: staffJSON.id,
    staffName: staffJSON.name,
    duration: appointmentJSON.duration,
    defaultTime: appointmentJSON.duration,
    conferenceType: matchedTime.meetingType,
    locationId: staffJSON.staffLocation[0]?.id,
    inPersonAddress: inPersonAddress.text
  };
};
