// css file import
import '@fortawesome/fontawesome-free/css/all.css';
import "jquery-ui/themes/base/all.css";
import "jquery-custom-select/dist/css/jquery.custom-select.min.css";
import "placeholder-loading/src/scss/placeholder-loading.scss"
import "./assets/css/Theme1.css";
import "./assets/css/common.css";
import "./assets/css/theme_widget.css";
import "./assets/css/fontFace.css";

// package's import
// import "@fortawesome/fontawesome-free/js/all.js";
import "jquery-ui/ui/widgets/tabs";
import "bootstrap-datepicker";

// JS file import
import "jquery-custom-select";
// import _ from "lodash";
import "./assets/js/Pageload";