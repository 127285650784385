import { APOData } from "./BuilderProps";
import { updateHandlebarsTemp, convertTimeToMinutes } from "./CommonMethod";
import { getServiceNextStep, updateStaffJson } from "./Serivceconfig";
import { GetAPI_deleteAPI_method } from "./Api";
import { getUtcTime } from "./worldTimeHelper";
import moment from "moment";

let selectedStaffIdArray = [];

const fetchSelectedStaffData = async () => {
  try {
    const { staffJSON } = APOData.APO_selectedData;
    
    // Call the API to get events based on the staff ID
    const StaffDatabyId = await GetAPI_deleteAPI_method(`EventsByStaffId?StaffId=${staffJSON.id}`, "GET");

    // Validate the API response to ensure it contains data
    if (!StaffDatabyId?.data || !StaffDatabyId.data.length) {
      return [];
    }

    // Update staff's business hours list if available in the API response
    staffJSON.businessHoursList = StaffDatabyId.data[0]?.businessHoursList ?? [];

    // Return the fetched booking list data
    return StaffDatabyId.data;

  } catch (error) {
    console.error("Error fetching Selected Staff Data:", error);
  }
};

// Function to handle team data and apply relevant booking rules
const handleTeamDataAndRules = async () => {
  const { APO_selectedData, Bookingrules } = APOData;
  const { serviceQParams } = APO_selectedData;

  // Filter for 'IsSkipTeamMembers' rule to determine if team members should be skipped
  const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsSkipTeamMembers");

  // If the rule is selected and no staff is specified, auto-select the first provider from the ProviderCount API
  if (IsSkipTeamMembers.isSelected && !serviceQParams.staff) {
    try {
      // Fetch provider count data and sort by totalCount (ascending)
      const { data: providerBookingCount } = await GetAPI_deleteAPI_method("ProviderCount", "GET");
      const sortedProviders = providerBookingCount.sort((a, b) => a.totalCount - b.totalCount);
      // Ensure the response contains valid provider data
      //if (ProviderBookingCount?.data && ProviderBookingCount.data.length) {

        serviceQParams.staff = sortedProviders[0].id; // Select the first provider's ID
        await updateStaffJson(serviceQParams.staff); // Update the staff JSON data based on the selected staff
      //}
    } catch (error) {
      console.error("Error fetching provider count data:", error);
    }
  }

  const { staffJSON } = APOData.APO_selectedData;

  // Fetch and update the selected staff ID array if staffJSON is present
  if (staffJSON) {
    selectedStaffIdArray = await fetchSelectedStaffData();
  }
}

const APO_previewDate = async (businessHours) => {
  // APOData.utcTime = await getUtcTime();
  const selectedDay = moment();
// Use Moment.js to work with the UTC time
  
  const today = selectedDay.format("dddd");
  const todayIndex = businessHours.findIndex((day) => day.day === today);
  const dayMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  for (let i = 0; i < businessHours.length; i++) {
    const dayIndex = (todayIndex + i) % businessHours.length;
    const dayData = businessHours[dayIndex];

    if (dayData.isOpened) {
      const daysToAdd = (dayMap[dayData.day] - todayIndex + 7) % 7;
      return selectedDay.clone().add(daysToAdd, "days");
    }
  }

  return null; // No open days found
};

// Function to initialize the date picker component
export const initdatepicker = async () => {
  let isInitialized = true; // Track initialization state

  // Fetch team data and apply rules before initializing the date picker
  await handleTeamDataAndRules();

  const { APO_selectedData } = APOData;
  const { businessHoursList } = APO_selectedData.staffJSON;

  // find the open day from provider business hours
  let openedDay = await APO_previewDate(businessHoursList);

  // Determine the initially selected day (default to tomorrow's date if not set)
  // let selectedDay = moment(new Date());
  if (!!APO_selectedData.date) {
    openedDay = APO_selectedData.date;
  } else {
    APO_selectedData.date = openedDay;
  }

  // Generate a comma-separated list of disabled days based on business hours
  const disabledDays = businessHoursList
    .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
    .filter((day) => day !== null)
    .join(",");

  // Initialize the date picker with the desired configuration
  $("#datepicker")
    .datepicker({
      // daysOfWeekDisabled: datesForDisable.replace(/,(\s+)?$/, ""),
      daysOfWeekDisabled: disabledDays,
      format: "mm/dd/yyyy",
      startDate: new Date(),
      weekStart: 1,
      todayHighlight: false,
      templates: {
        leftArrow: '<i class="fa fa-long-arrow-left"></i>',
        rightArrow: '<i class="fa fa-long-arrow-right"></i>',
      },
      beforeShowDay: function (date) {
        //return {
        //  content: `<span>${new Date(date).getDate()}</span>`
        //}
      },
    })
    .on("changeDate", async function (e) {
      // Update the selected day based on user input
      const selectedDay = moment(e.date);
      APO_selectedData.date = selectedDay;

      // Remove the 'today' class from all days in the date picker to avoid styling conflicts
      document.querySelectorAll(".datepicker-days .day").forEach((day) => day.classList.remove("today"));

      // If not initializing, reapply team data and rules
      if (!isInitialized) {
        await handleTeamDataAndRules();
      } else {
        isInitialized = false; // Mark as not initializing after the first change
      }

      // Update service duration based on the newly selected day
      serviceDuration(selectedDay);
    });

  // Set the date picker to display the initially selected day
  if (openedDay) {
    $("#datepicker").datepicker("setDate", openedDay.format("l")); 
  }
};

// Function to check service duration
const serviceDuration = (selectedDay) => {
  const { staffJSON, appointmentJSON } = APOData.APO_selectedData;

  // Get day name from selectedDay (e.g., "Monday")
  const dayName = selectedDay.format("dddd");

  // Find business hours for the given day
  const businessHours = staffJSON.businessHoursList.find((item) => item.day === dayName);

  // Return early if no business hours are found or if the business is closed
  if (!businessHours || !businessHours.isOpened) return;

  // Destructure business hours properties
  const { from, to, breakHours } = businessHours;

  // Format start and end times in "HH:mm" format
  const startTime = moment(from).format("HH:mm");
  const endTime = moment(to).format("HH:mm");

  // Convert the appointment duration to minutes
  const durationInMinutes = convertTimeToMinutes(appointmentJSON.duration);
  const timeData = { 
    startTime, 
    endTime, 
    duration: durationInMinutes, 
    breakHours 
  };

  // Generate service time slots for the given day using time data
  generateServiceTimeSlots(timeData, selectedDay);
};

// Function to calculate service time slots
const generateServiceTimeSlots = async (timeData, selectedDay) => {
  // Destructure necessary data from APOData
  const { APO_selectedData } = APOData;
  const { serviceQParams, staffJSON } = APO_selectedData;

  const { startTime, endTime, duration, breakHours } = timeData;

  // Check if the selected day is today and compare the current time
  const isToday = moment().format("DD") === selectedDay.format("DD");
  const isTodayDate = moment(new Date()).format("l") <= selectedDay.format("l");
  const currentTime = moment().format("HH:mm");
  // const isStartTimePassed = currentTime >= startTime;
  
  const availableServiceSlots = [];
  let bookedSlotsAndBreakHours = [];
  // let bookedSlots = [];
  
  if(selectedStaffIdArray.length > 0){
    // Filter bookings to include only those that match the selected day
    const filteredBookings = selectedStaffIdArray.filter(
      booking => booking?.startDate && moment(booking.startDate).isSame(selectedDay, "day")
    );
  
    // Map the filtered bookings to an array of objects with 'from' and 'to' times
    const bookedSlots = filteredBookings.map((booking) => ({
      from: booking.startDate,
      to: booking.endDate,
    }));

    // Combine booked slots with break hours
    bookedSlotsAndBreakHours = [...bookedSlots, ...breakHours];
  }

  // Initialize timeSlot with the start time, and iterate until it reaches the end time
  let timeSlot = moment(startTime, "HH:mm");
  const endMoment = moment(endTime, "HH:mm");


  while (timeSlot.isBefore(endMoment)) {
    const slotTime = timeSlot.format("HH:mm");

    // Check if the current slot falls within a break or booked slot
    const  { isBreak, breakEndTime } = isWithinBreakTime(slotTime, bookedSlotsAndBreakHours);

    // Add the slot to available slots if it's either not today or after the current time
    if (!isToday || !isTodayDate || currentTime < slotTime) {
      const fullTimestamp = moment(selectedDay).set({
        hour: timeSlot.hour(),
        minute: timeSlot.minute(),
      });
      availableServiceSlots.push({
        slot: slotTime,
        timestamp: fullTimestamp.unix(),
        break: isBreak,
      });
    }

    // If the slot is within a break, skip to the end of the break; otherwise, increment by duration
    timeSlot = isBreak ? moment(breakEndTime, "HH:mm") : timeSlot.clone().add(duration, "minutes");

  }

  // Select the DOM element where the appointment slots will be rendered
  const SlotEle = document.querySelector(".Service .AppointmentSlot");

  // Get the Handlebars template from the script element
  const template = document.getElementById(`template_AvailableSlot`).innerHTML;

  // Determine the next step based on the service step and staff availability
  const checkParamsbyStep = serviceQParams.step === "Datetime" ? true : staffJSON;
  const { nextStep } = await getServiceNextStep(checkParamsbyStep); // Determine the next step based on staff availability

  // Prepare data for the Handlebars template
  const templateData = {
    Bookingslots: availableServiceSlots.filter(slot => !slot.break), // Available service slots data
    serviceQParams,
    step: nextStep,
  };
  
  // Compile and update the Handlebars template with the prepared data
  await updateHandlebarsTemp(SlotEle, template, templateData);
};

// Function to check if the time is within break hours
const isWithinBreakTime = (time, breakHours) => {
  if (!breakHours || breakHours.length === 0) return { isBreak: false, breakEndTime: null };
  let breakInfo = { isBreak: false, breakEndTime: null };

  // Iterate through break times and find if the time falls within any break period
  breakHours.forEach((breakTime) => {
    const breakStartTime = moment(breakTime.from).format("HH:mm");
    const breakEndTime = moment(breakTime.to).format("HH:mm");
    if (time >= breakStartTime && time < breakEndTime) {
      breakInfo = { isBreak: true, breakEndTime: breakEndTime };
    }
    // return time >= breakStartTime && time < breakEndTime;
  });
  return breakInfo;
};
