const emailPattern =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

// import validator from "validator";
import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";
import isIn from "validator/lib/isIn";
import isMobilePhone from "validator/lib/isMobilePhone";

// export const validateField = (field) => {

//     field.classList.remove("has-error");
//     if (field.tagName.toLowerCase() === "select") {
//       field.parentElement.classList.remove("has-error");
//     }
//     if (field.value.trim() === "") {
//       field.classList.add("has-error");
//     } else if (field.type === "email" && !emailPattern.test(field.value)) {
//       field.classList.add("has-error");
//     } else if (field.type === "number" && isNaN(field.value)) {
//       field.classList.add("has-error");
//     } else if (
//       field.tagName.toLowerCase() === "select" &&
//       field.value === "select"
//     ) {
//       field.parentElement.classList.add("has-error");
//     }
//   };

//   export const validationCheck = () => {
//     const custInput = document.querySelectorAll(".APO_custDetail .input-field");
//     const APO__bookingBtn = document.querySelector(".APO__bookingBtn");

//     custInput.forEach((item) => {
//       APOcontact[item.getAttribute("data-name")] = item.value;
//     });

//     if (
//       APOcontact.name !== "" &&
//       (APOcontact.email !== "" ? emailPattern.test(APOcontact.email) : false) &&
//       (APOcontact.number !== "" ? !isNaN(APOcontact.number) : false) &&
//       APOcontact.country !== "select"
//     ) {
//       APO__bookingBtn.disabled = false;
//     } else {
//       APO__bookingBtn.disabled = true;
//     }
//   };

export const validateField = (field, value, validationSchema) => {
  let isValid = true;
  let errorMessage = "";

  const rules = validationSchema[field];

  // Early exit if no validation rules are defined
  if (!rules) {
    return { isValid, errorMessage };
  }

  for (const rule of rules) {
    switch (rule.type) {
      case "isRequired":
        if (!isLength(value, { min: 1 })) {
          isValid = false;
          errorMessage = rule.message || "This field is required.";
        }
        break;

      case "isEmail":
        if (!isEmail(value)) {
          isValid = false;
          errorMessage = rule.message || "Please enter a valid email address.";
        }
        break;

      case "isIn":
        if (!isIn(value, rule.options)) {
          isValid = false;
          errorMessage = rule.message || "Invalid value.";
        }
        break;

      case "isMobilePhone":
        if (!isMobilePhone(value, rule.locale)) {
          isValid = false;
          errorMessage = rule.message || "Please enter a valid phone number.";
        }
        break;

      case "maxLength":
        if (rule.arrayLength < rule.maxLength) {
          isValid = false;
          errorMessage = rule.message || `No more than ${rule.maxLength} items allowed.`;
        }
      break;

      // Extendable case for future validation types
      default:
        break;
    }

    // Exit loop early if invalid
    if (!isValid) break;
  }

  return { isValid, errorMessage };
};

export const toggleSubmitButton = (form, validationSchema, submitBtn) => {
  let isFormValid = true;

  form.querySelectorAll("input, select").forEach((inputElement) => {
    // Handle jQuery custom select inputs
    const value = inputElement.classList.contains("jqueryCustomSelect")
      ? $(inputElement).val()
      : inputElement.value;

    const { isValid } = validateField(
      inputElement.name,
      value,
      validationSchema
    );

    // If any field is invalid, disable the form submission and exit early
    if (!isValid) {
      isFormValid = false;
      return; // Exit loop early for performance
    }
  });

  // Enable or disable the submit button based on form validity
  submitBtn.disabled = !isFormValid;
};

export const showValidationMessages = (inputElement, errorMessage) => {
  let parentElement = inputElement.parentElement;
  const checkClass = ["give-star-rating", "jqueryCustomSelect"];

  if (checkClass.some((cls) => inputElement.classList.contains(cls))) {
    parentElement = parentElement.parentElement;
  }

  // Clear previous error messages for this field
  const existingError = parentElement.querySelector(".has-error");
  if (existingError) existingError.remove();

  // Only create and append an error message if there's an error
  if (errorMessage) {
    const errorMessageElement = document.createElement("div");
    errorMessageElement.className = "has-error";
    errorMessageElement.style.fontSize = "13px";
    errorMessageElement.textContent = errorMessage;
    parentElement.appendChild(errorMessageElement);
  }
};
