import {
  GetAPI_deleteAPI_method,
  GetAPI_deleteAPI_method_2,
  PostAPI_PutAPI_method,
} from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { OnboardingData } from "./OnboardingData";
import {
  base64DecodeUnicode_HTML,
  base64EncodeUnicode_HTML,
  getUrlParams,
  hideLoaderAfterPageLoad,
  setSectionLoader,
} from "./CommonMethod";
import { fetchBookingRules, themeTypeSetting } from "./ThemeOnLoadSettings";
import { bindMeetingTemplate } from "./teamMeeting/MeetingTemplate";

const validateKey = async () => {
  try {
    const get_Tid = await getUrlParams("Tid");
    const get_did = await getUrlParams("did");
    // Get Tid parameter from URL
    const windowLocation = window.location;
    let bookingSlugURL = windowLocation.href;

    if (!get_Tid || !get_did) {
      // Encode the URL and fetch the staff slug URL
      // const slugUrl = base64EncodeUnicode_HTML("https://mind-peace.dev.book-me.co/sk");
      const slugUrl = base64EncodeUnicode_HTML(windowLocation.origin + windowLocation.pathname); 
      const { data: slug } = await GetAPI_deleteAPI_method_2(
        `GetStaffSlugURL?base64EncodedUrl=${slugUrl}`,
        "GET"
      );

      // Decode the retrieved slug
      bookingSlugURL  = base64DecodeUnicode_HTML(slug.slugValue);

      // Extract dependency parameters from the decoded slug
      APOData.Tid = await getUrlParams("Tid", bookingSlugURL);
      APOData.did = await getUrlParams("did", bookingSlugURL);
      // APOData.APO_selectedData.staffID = await getUrlParams("staff", bookingSlugURL);

      const slugType = await getUrlParams("type")
      APOData.slugType = slugType ? slugType : "team";
    } 
    else {
      APOData.slugType = "business";
      // update dependency parameters from the URL
      APOData.Tid = get_Tid;
      APOData.did = get_did;
    }

    // update staffID parameters from the URL
    APOData.APO_selectedData.staffID = await getUrlParams("staff", bookingSlugURL);

    // Prepare data for token validation
    const keyValidate = {
      token: APOData.Tid,
      validity: "Long Live",
    };

    // Send the token for validation
    const keyResponse = await PostAPI_PutAPI_method(
      "ChatWindowToken",
      "POST",
      keyValidate
    );
    if (keyResponse.status === 400) {
      return false;
    } else {
      const {
        accesstoken,
        isCashConnected,
        isPayPalConnected,
        isStripeConnected,
        stripePublishableKey,
        stripeAccountId,
      } = keyResponse.data;

      // Set the token in the APIHandler instance and update the cookie
      const newToken = `Bearer ${accesstoken}`;
      document.cookie = "tk" + "=" + (newToken || "");

      // Update payment modes and Stripe data if connected
      APOData.paymentModeList = {
        isCashConnected,
        isPayPalConnected,
        isStripeConnected,
      };

      // update accept payments perms
      if (isStripeConnected) {
        APOData.stripeData = {
          stripePublishableKey,
          stripeAccountId,
        };
      }

      return true;
    }
  } catch (error) {
    // Handle errors gracefully
    console.error("An error occurred:", error);
    return false;
  }
};

(async function () {
  //add loader
  setSectionLoader(true, "body");

  const isValidKey = await validateKey();
  if (!isValidKey) {
    console.log("Initialization aborted due to invalid key.");
    return;
  }
  
  if(APOData.slugType === "meeting") {
    //bind meeting template
    await bindMeetingTemplate();
  }
  else {
    // Call the function
    await handleThemeLoading();
  }

  hideLoaderAfterPageLoad();

})();

async function handleThemeLoading() {
  try {
    const hostUrl = window.location.hostname;
    const apiUrl = `BusinessTemplateHTML?SubDomainName=${hostUrl}&DomainId=${APOData.did}`;

    const response = await GetAPI_deleteAPI_method(apiUrl, "GET");
    const { data } = response;

    // Insert HTML and apply styles
    const insertData = document.getElementById("insertData");
    insertData.style.display = "none";
    insertData.innerHTML = base64DecodeUnicode_HTML(data.html);
    Object.assign(templateJSON, JSON.parse(data.metaJson));

    
    // Additional initialization methods
    await fetchBookingRules();
    await themeTypeSetting();
    await OnboardingData();
  } catch (error) {
    console.error("Error content:", error);
    // You may want to handle the error more gracefully here
  }
}
