import { GetAPI_deleteAPI_method_2 } from "./Api";
import { APOData } from "./BuilderProps";
import { base64DecodeUnicode_HTML, base64EncodeUnicode_HTML, hideLoaderAfterPageLoad, setSectionLoader, updateHandlebarsTemp } from "./CommonMethod";
import { appointmentLayout } from "./ServiceFilterHtml";
import { companySpecificInformation } from "./SingleInformation";


window.singleTeamMethod = async (event) => {
    // Destructure required data from APOData
    const { slugType } = APOData;

    if(slugType === "team") {
      event.preventDefault();
    }

    // event.preventDefault();
    // const { APOtype, APO_selectedData } = APOData;
    // const currentUrl = new URL(window.location.href);
    // const { currentTarget } = event;
    // const { teamid } = currentTarget.dataset;
    // const isSingleStaffResult = APO_selectedData.staffID;

    // // Early return if the team ID is the same as the current staff
    // if (teamid === isSingleStaffResult) return;

    // const slugUrl = base64EncodeUnicode_HTML(currentTarget.href); 

    // const {data: slug} = await GetAPI_deleteAPI_method_2(`GetStaffSlugURL?base64EncodedUrl=${slugUrl}`, "GET");
    // const decodeSlug = base64DecodeUnicode_HTML(slug.slugValue);
    // console.log(decodeSlug);
    // // location.href = decodeSlug;

    // // Update the 'staff' parameter in the URL
    // currentUrl.searchParams.set('staff', teamid);
    // history.pushState(null, '', currentUrl.href);
    
    // // Fetch company-specific information
    // await companySpecificInformation();

    // await appointmentLayout(APOData[APOtype]);

};


export const hideTeamSection = (ishide) => {
  const { Bookingrules } = APOData;

  // Filter the IsOurTeamVisible rules
  const IsOurTeamVisible = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsOurTeamVisible");
  const TeamRule = ishide || !IsOurTeamVisible.isSelected;

  const rowContainers = document.querySelectorAll(".row-container-outer");

  rowContainers.forEach((rowContainer) => {
    // Check if the row container has a TeamList element
    const teamListElements = rowContainer.querySelector(".TeamList");

    if (teamListElements) {
      const columnOuterElements =
        rowContainer.querySelectorAll(".column-Outer");

      columnOuterElements.forEach((columnOuter) => {
        /// Get all builder elements inside the current column-Outer
        const builderElements = columnOuter.querySelectorAll(".widget[builder-element]");

        if (columnOuterElements.length === 1 && builderElements.length === 1) {
          // Hide the entire row-container-outer if it contains only one column-Outer with one builder element
          rowContainer.style.display = TeamRule ? "none" : "";
        } else if (builderElements.length === 1) {
          // Hide the column-Outer if it contains exactly one builder element
          columnOuter.style.display = TeamRule ? "none" : "";
        } else {
          // Otherwise, hide the TeamList element itself
          teamListElements.style.display = TeamRule ? "none" : "";
        }
      });
    }
  });

  // hideLoaderAfterPageLoad();
  
};

export const displayTeamList = async () => {
  // Destructure required data from APOData
  const { onboardingData, Bookingrules, slugType, APO_selectedData } = APOData;
  
  // Filter the IsOurTeamVisible rules
  const IsOurTeamVisible = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsOurTeamVisible"
  );

  if (IsOurTeamVisible.isSelected) {
    const appendElement = document.querySelector(`.TeamList .team-wrapper`);
    const template = document.getElementById(`template_teamList`).innerHTML;

    //clear innerHTML
    appendElement.innerHTML = "";

    await updateHandlebarsTemp(appendElement, template, {
      teamList: slugType === "team" ? [APO_selectedData.staffJSON] : onboardingData.staffMembersList,
    }); // Compile Handlebars template method
  }
};
