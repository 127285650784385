import { APOData, templateJSON } from "./BuilderProps";
import { find } from 'lodash-es';

export const Set__APOtype = async () => {
  const hash = window.location.hash;
  const searchParams = new URLSearchParams(hash).get("type");
  return searchParams ? searchParams : APOData.appointmentFilter[0];
};
export const serviceQueryParams = async (params) => {
  try {
    const searchParams = new URLSearchParams(params);

    // Extract query parameters into a structured object
    APOData.APO_selectedData.serviceQParams = {
      step: searchParams.get("step"),
      products: searchParams.get("products"),
      type: searchParams.get("type"),
      staff: searchParams.get("staff"),
      slot: searchParams.get("slot"),
      staffSelected: searchParams.get("staffSelected"),
      anyProvider: searchParams.get("anyProvider"),
      re: JSON.parse(searchParams.get("re")),
      bid: searchParams.get("bid"),
    };
    let { serviceQParams } = APOData.APO_selectedData;

    // Update APOData and fetch related data based on the query params
    APOData.APO_selectedData.serviceActiveStep = (serviceQParams.step) ? serviceQParams.step : "Servicelist";
    APOData.APO_selectedData.timeslot = (serviceQParams.slot) ? serviceQParams.slot : null;

    await updateSelectAPOJson(serviceQParams.products);
    await updateStaffJson(serviceQParams.staff);

    if (serviceQParams.type) {
      APOData.APOtype = serviceQParams.type === "Appointment" ? "Service" : serviceQParams.type;
    }

    if (serviceQParams.staffSelected) {
      APOData.APO_selectedData.staffSelected = JSON.parse(serviceQParams.staffSelected);
    }

    if (serviceQParams.anyProvider) {
      APOData.APO_selectedData.anyProvider = JSON.parse(serviceQParams.anyProvider);
    }

    if (serviceQParams.re) {
      APOData.reschedule = serviceQParams.re;
      APOData.bookingId = serviceQParams.bid;
    }

    return serviceQParams;
  } catch (error) {
    console.error("Error processing service query parameters:", error);
    throw error;
  }
};

// Update APOData with selected product information
const updateSelectAPOJson = async (productId) => {
  const { APOtype, APO_selectedData } = APOData;
  if (productId) {
    const productData = find(APOData[APOtype], { id: productId }); // Find the data by ID
    APO_selectedData.appointmentJSON = productData;
  }
  else {
    APO_selectedData.appointmentJSON = null;
  }
};

// Update APOData with selected staff information
export const updateStaffJson = async (staffId) => {
  const { onboardingData, APO_selectedData } = APOData;
  if (staffId) {
    const staffData = find(onboardingData.staffMembersList, { id: staffId }); // Find the data by ID
    APO_selectedData.staffJSON = staffData;
  }
  else {
    APO_selectedData.staffJSON = null;
  }
};

export const getServiceNextStep = async (obj) => {
  const { APO_selectedData } = APOData;
  const { serviceActiveStep } = APO_selectedData;
  const { steps } = templateJSON.StyleObj["Service"];

  const stepIndex = steps.findIndex((step) => step.name === serviceActiveStep);
  const nextpreStep = {
    nextStep: obj ? steps[stepIndex + 1].name : serviceActiveStep,
  };

  return nextpreStep;
};

// export const getpreviouStep = async() => {
//   const { staffRules, APO_selectedData, } = APOData;
//   const { serviceActiveStep } = APO_selectedData;
//   const {appointmentJSON, staffJSON} = APO_selectedData;
//   const { steps } = templateJSON.StyleObj["Service"];

//   let previouStep = ""
//   if ((staffRules.length && serviceActiveStep === "Datetime") || serviceActiveStep === "stafflist") {
//     previouStep = "#/book";
//   }
//   else {
//     const stepIndex = steps.findIndex(step => step.name === serviceActiveStep);
//     previouStep = `#/book/step=${steps[stepIndex - 1]}&products=${appointmentJSON.id}&type=${appointmentJSON.APOtype}&staff=${staffJSON.id}`;
//   }
//   console.log(serviceActiveStep)
//   console.log(previouStep)

//   return previouStep;

// };
