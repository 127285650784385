export const getUtcTime = async () => {
  const url = "http://worldtimeapi.org/api/timezone/Etc/UTC";

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.utc_datetime; // Return the UTC datetime directly
  } catch (error) {
    console.error("Error fetching UTC time:", error);
    throw new Error("Unable to fetch UTC time"); // Rethrow error for further handling
  }
};
