import Handlebars from "handlebars";
import { templateJSON } from "./BuilderProps";
import {
  convertTimeToMinutes,
  convertUnixTimestamp,
  momentDateTimeFormat,
} from "./CommonMethod";
import moment from "moment";

export const registerHelper = () => {
  // Register Handlebars partials
  const partials = [
    {
      name: "commonHtmlof_servicegridlist",
      id: "commonHtmlof_servicegridlist",
    },
    { name: "SignupFormField", id: "template_SignupFormField" },
    { name: "orderdetail", id: "template_orderdetail" },
    {
      name: "PaymentPlaceholderLoading",
      id: "template_PaymentPlaceholderLoading",
    },
    { name: "servicegrid", id: "template_servicegrid" },
    { name: "filterApo", id: "template_filterApo" },
    { name: "ApoBackStep", id: "template_ApoBackStep" },
    { name: "APORuleandNoData", id: "template_APORuleandNoData" },
    { name: "Eventslocation", id: "template_Eventslocation" },
  ];

  partials.forEach((partial) => registerPartial(partial.name, partial.id));

  // Helper to apply styles from a style object
  Handlebars.registerHelper("applyStyles", function (name, stylesKey, color) {
    var styles = templateJSON.StyleObj[name][stylesKey]; // Retrieve styles from StyleObj
    return new Handlebars.SafeString(
      `style="${StylejsonFN(styles)}${color.data ? "" : ` color:${color}`}"`
    );
  });

  // Helper to apply event color
  Handlebars.registerHelper("eventColor", function (color, options) {
    return new Handlebars.SafeString(`style="color: ${color}"`);
  });

  // Equality check helpers
  Handlebars.registerHelper("if_eq", function (a, b, opts) {
    return a === b ? opts.fn(this) : opts.inverse(this);
  });
  Handlebars.registerHelper("eq", (a, b) => a === b);
  Handlebars.registerHelper("noteq", (a, b) => a !== b);
  Handlebars.registerHelper('not', (value) => !value );

  // Or condition helper
  Handlebars.registerHelper("or", function () {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  });

  // And condition helper
  Handlebars.registerHelper("and", function () {
    return Array.prototype.every.call(arguments, Boolean);
  });

  Handlebars.registerHelper("if_noteq", function (a, b, opts) {
    return a !== b ? opts.fn(this) : opts.inverse(this);
  });
  Handlebars.registerHelper("if_or_with_and", function (a, b, c, opts) {
    return a || (b && c) ? opts.fn(this) : opts.inverse(this);
  });
  Handlebars.registerHelper("ifIn", function (value, list, options) {
    if (!Array.isArray(list)) {
      list = [list];
    }
    // return list.includes(value) ? options.fn(this) : options.inverse(this);
    return list.includes(value) ? options.fn(this) : options.inverse(this);
  });

  // Register an array helper to create arrays in Handlebars
  Handlebars.registerHelper("includeInArray", (...args) => args.slice(0, -1));

  Handlebars.registerHelper("if_and", function (a, b, opts) {
    // If `a` or `b` are strings representing booleans, parse them
    const parsedA = typeof a === "string" ? JSON.parse(a) : a;
    const parsedB = typeof b === "string" ? JSON.parse(b) : b;
    return parsedA && parsedB ? opts.fn(this) : opts.inverse(this);
  });
  Handlebars.registerHelper("trueAndNull", function (value) {
    // If value are strings representing booleans, parse them
    const parsed_value = typeof value === "string" ? JSON.parse(value) : value;
    return parsed_value === true || parsed_value === null;
  });

  // Day, Date and time formatting helpers
  Handlebars.registerHelper("formatDay", (data, format) =>
    moment().day(data).format(format)
  );
  Handlebars.registerHelper("formatDateTime", (data, format) =>
    moment(new Date(data)).format(format)
  );
  Handlebars.registerHelper("formatTimestamp", (timestamp, format) =>
    !!timestamp ? convertUnixTimestamp(timestamp, format) : ""
  );

  // Time conversion helper
  Handlebars.registerHelper("convertTimeToMinutes", function (timeString) {
    const totalMinutes = convertTimeToMinutes(timeString);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours}hr ${minutes}min` : `${totalMinutes}min`;
  });

  // String transformation helpers
  Handlebars.registerHelper("toLowerCase", (str) => str.toLowerCase());

  // first&last letter of string
  Handlebars.registerHelper("returnOnlyZeroindex", function (name, optios) {
    if (!!name) {
      const words = name.trim().split(" ");
      if (words.length === 1) {
        return `${name[0].toUpperCase()}${name[name.length - 1].toUpperCase()}`;
      }
      return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    }
  });

  // Array length checker
  Handlebars.registerHelper("ifArrayLength", function (array, opts) {
    return array && array.length > 0 ? opts.fn(this) : opts.inverse(this);
  });

  // Range helper to generate an array of numbers
  Handlebars.registerHelper("range", function (start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  });

  // Team url helper
  Handlebars.registerHelper(
    "teamUrl",
    (id, opts) =>
      `${window.location.origin}${window.location.pathname}${window.location.search}&staff=${id}`
  );

  Handlebars.registerHelper("json", function (context) {
    return JSON.stringify(context, null, 2);
  });

  // json helper to make JSON parse
  Handlebars.registerHelper("JSONparse", function (context) {
    return !!context ? JSON.parse(context) : "";
  });

  // Register a custom Handlebars helper called "getGoogleMapsLink"
  Handlebars.registerHelper("getGoogleMapsLink", (addressObj) => businessAddressJSON(addressObj));

  // Multiply Handlebars helper
  Handlebars.registerHelper("multiply", function(a, b) {
    return parseInt(a) * parseInt(b);
  });
};

// Helper function to register a partial safely
const registerPartial = async (partialName, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    Handlebars.registerPartial(partialName, element.innerHTML);
  }
  // else {
  //   console.warn(
  //     `Element with id "${elementId}" not found, partial "${partialName}" not registered.`
  //   );
  // }
};

export const businessAddressJSON = (addressObj) => {
  // Check each field and only include non-empty, non-null values
  const addressComponents = [
    addressObj.address || "",
    addressObj.city || "",
    addressObj.state || "",
    addressObj.country || "",
    addressObj.zip || "",
  ].filter(Boolean); // Remove empty or null values

  // Join the valid address components into a single string
  const fullAddress = addressComponents.join(", ");

  // Replace spaces with '+' to format for Google Maps
  const formattedAddress = fullAddress.replace(/\s/g, "+");

  // Construct the Google Maps URL
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

  return {
    link: googleMapsLink,
    text: fullAddress,
  };
};

const StylejsonFN = (json) => {
  return Object.keys(json)
    .map(function (key) {
      return (
        key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase() +
        ": " +
        json[key] +
        ";"
      );
    })
    .join("");
};
