import { APOData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { appointmentTypeList } from "./ServiceFilterHtml";
import { bindThemeData } from "./ThemeOnLoadSettings";
import { PageRouting } from "./Routes";
import { get__ServiceEventClass } from "./Serivce";
import { fetchSingleStaffData } from "./SingleStaff";
import { fetchAllLocations } from "./MultiLocation";
import { displayTeamList } from "./Teams";

export const OnboardingData = async () => {
  try {
    // Destructure required data from APOData
    const { slugType, APO_selectedData } = APOData;

    const response = await GetAPI_deleteAPI_method("Onboarding", "GET");
    const { data } = response;
    APOData.onboardingData = data; // service List

    if(slugType === "team") {
      //fetch staff data
      await fetchSingleStaffData(APO_selectedData.staffID);
      await displayTeamList();
    }
    // else {
    //   APOData.businessTimeZone = data.businessAddressList[0].timeZone; //globally set business time zone
    //   moment.tz.setDefault(APOData.businessTimeZone);
    // }

    // Company specific information detail
    // await companySpecificInformation();
    await fetchAllLocations(slugType);
    await appointmentTypeList(data);
    await get__ServiceEventClass();
    await bindThemeData(); // template setting's

    // if (!window.location.hash) {
    //   await appointmentLayout(APOData[APOData.APOtype], "Service");
    // }

    PageRouting();
  } catch (error) {
    console.error("Error during onboarding data processing:", error);
  }
};

export const APO__DataUpdate = async (data, type) => {
  const { userCurrencySymbol } = APOData.onboardingData;
  return data.map((item) => ({
    ...item,
    currencySymbol: userCurrencySymbol,
    APOtype: type,
  }));
};
