
// global variable
export let APOData = {
  onboardingData: null,
  Bookingrules: null,
  Service: [],
  GlobalEvents: [],
  Class: [],
  APO_selectedData: {
    appointmentID: null,
    staffID: null,
    date: null,
    timeslot: null,
    appointmentJSON: null,
    staffJSON: null,
    serviceQParams: {},
  },			
  APOtype: "",
  CountriesList: [],
  daysOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  slugType: null,
};
export const templateJSON = {};
globalThis.APOData = APOData;
globalThis.templateJSON = templateJSON;

