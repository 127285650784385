import StarRating from "star-rating.js";
import "star-rating.js/dist/star-rating.css"; // Import the CSS for styling
import { GetAPI_deleteAPI_method, PostAPI_PutAPI_method } from "./Api";
import {
  showValidationMessages,
  toggleSubmitButton,
  validateField,
} from "./FormValidation";
import { setBtnLoader, updateHandlebarsTemp } from "./CommonMethod";

export const initializeReviews = () => {
  new StarRating(".give-star-rating", {
    stars: 5, // Number of stars
    value: 0, // Default rating value
    tooltip: false, // Show tooltip on hover
    readOnly: false, // Whether the rating is read-only
    clearable: true,
    maxStars: 5,
    prebuilt: false,
    stars: null,
    callback: (newRating, el) => {
      console.log(`Selected rating: ${newRating}`);
    },
  });

  attachReviewFormEventListeners();
  SubmittedReviewList();
};

const attachReviewFormEventListeners = () => {
  const reviewForm = document.getElementById("reviewForm");
  const reviewBtn = reviewForm.querySelector('button[type="submit"]');
  const starRating = document.querySelector(".give-star-rating");

  // Define validation schemas for each form
  const validationSchema = {
    name: [{ type: "isRequired" }],
    email: [{ type: "isRequired" }, { type: "isEmail" }],
    review: [{ type: "isRequired" }],
    rating: [
      { type: "isRequired" },
      {
        type: "isIn",
        options: ["1", "2", "3", "4", "5"],
        message: "Your rating is required",
      },
    ],
  };

  // Common event handler function
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    const { isValid, errorMessage } = validateField(
      name,
      value,
      validationSchema
    );
    showValidationMessages(event.target, errorMessage);
    toggleSubmitButton(reviewForm, validationSchema, reviewBtn);
  };

  // Attach event listeners to form fields
  reviewForm.addEventListener("keyup", handleFieldChange);
  starRating.addEventListener("change", handleFieldChange);
};

window.submittMyReview = async (event) => {
  event.preventDefault(); // Prevent default form submission

  // Create a reusable message element
  const createStatusMessage = (message, className) => {
    const reviewStatus = document.createElement("div");
    reviewStatus.className = className;
    reviewStatus.textContent = message;
    event.target.appendChild(reviewStatus);
    setTimeout(() => reviewStatus.remove(), 3000); // Auto-remove after 3 seconds
  };

  // Display loader on the submit button
  setBtnLoader(true, event.submitter, [27]);

  const formData = new FormData(event.target);
  const formValues = {
    name: formData.get("name"),
    email: formData.get("email"),
    review: formData.get("review"),
    rating: formData.get("rating"),
    eUserReviewsType: "Pending",
  };

  try {
    // Post the review
    const reviewResponse = await PostAPI_PutAPI_method(
      "UserReviews",
      "POST",
      formValues
    );

    // Check response and update the review status message
    const { success, message } = reviewResponse.data;
    const statusClass =
      success && reviewResponse.status === 200 ? "info-alert" : "danger-alert";

    createStatusMessage(message, statusClass);

    // Clear the form if the submission is successful
    if (success && reviewResponse.status === 200) {
      event.target.reset();
    }
  } catch (error) {
    console.error("Error in submitting review:", error);
    createStatusMessage("An error occurred. Please try again.", "danger-alert");
  } finally {
    setBtnLoader(false, event.submitter); // Hide loader
  }
};

const SubmittedReviewList = async () => {
  const reviewResponse = await GetAPI_deleteAPI_method(
    "UserReviewsEditor",
    "GET"
  );

  // Select the booking wrapper element
  const userReviewsList = document.querySelector(".userReviewsList");

  // Get the template for booked appointment list
  const template = document.getElementById(
    "template_userReviewsList"
  ).innerHTML;

  // Update the booking wrapper with the new content
  await updateHandlebarsTemp(userReviewsList, template, {
    UserReviews: reviewResponse.data,
  });

  const useRatedStar = document.querySelectorAll(".user-rated-star");
  useRatedStar.forEach((items) => {
    new StarRating(items, {
      stars: 5, // Number of stars
      tooltip: false, // Show tooltip on hover
      readOnly: true, // Whether the rating is read-only
      clearable: true,
      maxStars: 5,
      prebuilt: false,
      stars: null,
      callback: (newRating, el) => {
        console.log(`Selected rating: ${newRating}`);
      },
    });
  });
};
