import toastr from 'toastr';

// Global Toastr Options
toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  timeOut: '2000',
  preventDuplicates: true,
  newestOnTop: true,
};

export default toastr;