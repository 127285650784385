import { templateJSON } from "./BuilderProps";
import { getMeetingTypeNameAndIocn, updateHandlebarsTemp } from "./CommonMethod";
import { APOData } from "./BuilderProps";
import { getServiceNextStep, Set__APOtype } from "./Serivceconfig";
import { get__ServiceEventClass } from "./Serivce";
import "jquery-custom-select";

const NextStep = async (steps, isTeamSlug) => {
  // Destructure necessary data from APOData and templateJSON
  const { APOtype, Bookingrules, onboardingData } = APOData;

  //filter isMemberCalenderShow only from staff list
  const filteredVisibleStaff = onboardingData.staffMembersList.filter(
    (member) => member.isMemberCalenderShow
  );

  const isSingleVisibleStaff = filteredVisibleStaff.length <= 1;

  // Get index of the "datetime" step and the skip rule for team members
  const indexOfDatetime = steps.findIndex((step) =>
    step.name.includes("datetime")
  );
  const isSkipTeamMembers = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsSkipTeamMembers"
  )?.isSelected;

  let nextStep = null;

  switch (APOtype) {
    case "Class":
    case "GlobalEvents":
      nextStep = "userdetails";
      break;
    // case "Class":
    //   nextStep = steps[indexOfDatetime].name;
    //   break;
    default:
      const { nextStep: serviceNextStep } = await getServiceNextStep(true);
      nextStep =
        (isSkipTeamMembers || isSingleVisibleStaff || isTeamSlug) &&
        serviceNextStep === "stafflist"
          ? steps[indexOfDatetime].name
          : serviceNextStep;
      break;
  }
  return nextStep;
};

export const appointmentLayout = async (result) => {
  // Fetch appointment data based on the selected value
  let appointmentData =
    result && result.length ? result : await get__ServiceEventClass();
  //if (data.length) {
  // Destructure necessary data from APOData and templateJSON
  const {
    APOtype,
    APO_selectedData,
    slugType,
    appointmentFilter,
    Bookingrules,
    onboardingData,
    locationList,
  } = APOData;
  const { layout, steps } = templateJSON.StyleObj["Service"];
  const { staffJSON, serviceQParams } = APO_selectedData;

  //filter event based on selected location
  if (APOtype === "GlobalEvents" || APOtype === "Class") {
    appointmentData = appointmentData
    .filter(item =>
      item.locationType === "OnlinePhysical" || 
      item.locationType === "Online" || 
      (item.locationId === serviceQParams.locId && item.locationType === "Physical")
    )
  }

  // Check if the "IsServicesVisible" rule is selected
  const IsBookAppointmentVisible = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsBookAppointmentVisible"
  )?.isSelected;

  // Get the target element and template & Handlebars template
  const appendElement = document.querySelector(`.Service .APO_booking_content`);
  const template = document.getElementById(
    `template_service${layout}`
  ).innerHTML;
  appendElement.innerHTML = ""; //clear innerHTML

  const isTeamSlug = slugType === "team";
  const matchingType = appointmentFilter.find(
    (items) => items.typeName === APOtype
  );

  // Prepare the data for the template
  const templateData = {
    appointmentData,
    serviceQParams,
    step: await NextStep(steps, isTeamSlug),
    APOtypeName: matchingType.displayName,
    APOtype,
    appointmentFilter,
    IsBookAppointmentVisible,
    isbackbtn: locationList.length > 1,
  };

  if(APOtype === "Service") {
    Object.assign(templateData, {
      staffData: {
        singleStaff: isTeamSlug ? staffJSON : [],
        staffSelected: isTeamSlug && APOtype === "Service" ? false : true,
      },
      teamAvailableForLocation: !isTeamSlug
      ? onboardingData.staffMembersList.length
      : true,
    });
  }

  // Compile and update the Handlebars template
  await updateHandlebarsTemp(appendElement, template, templateData);

  // Apply custom select functionality
  $(".S_customSelect").customSelect();
  //}
};

export const appointmentTypeList = async (data) => {
  try {
    // Destructure necessary data from APOData and templateJSON
    const { Bookingrules } = APOData;

    // Check if the "IsServicesVisible" rule is selected
    const IsServicesVisible = Bookingrules.bookingRulesLists.find(
      (rule) => rule.keyName === "IsServicesVisible"
    )?.isSelected;

    const APOtypeList = [
      {
        condition: data.isServiceAdded && IsServicesVisible,
        typeName: "Service",
        displayName: "Services",
      },
      // {
      //   condition: data.isClassAdded,
      //   typeName: "Class",
      //   displayName: "Classes",
      // },
      {
        condition: data.isEventAdded,
        typeName: "Event",
        displayName: "Events",
      },
      {
        condition: data.isGlobalEventAdded,
        typeName: "GlobalEvents",
        displayName: "Event planning",
      },
    ];

    // Apply the filtered results to APOData.appointmentFilter
    APOData.appointmentFilter = APOtypeList.filter((item) => item.condition);

    // Set APOtype asynchronously
    APOData.APOtype = await Set__APOtype();
  } catch (error) {
    console.error("Error setting appointment types:", error);
  }
};
