import { APOData } from "./BuilderProps";
import { v4 as uuidv4 } from "uuid";
import {
  showValidationMessages,
  toggleSubmitButton,
  validateField,
} from "./FormValidation";

window.APO__addGuestEmail = (event, element) => {
  const { target } = event;

  // Store unique guest email IDs globally
  APOData.guestEmails = [];

  // Create guest email input container
  const emailWrapper = document.createElement("div");
  emailWrapper.className = "flex flex-col gap-2";
  emailWrapper.innerHTML = `
    <div class="flex flex-col">
      <label class="property-label mb-0">Add guest email's</label>
      <span class="text-mute" style="font-size: 12px;line-height: normal;color: #9f9f9f;">(Notify up to 10 additional guests of the scheduled event.)</span>
    </div>
    <div class="guestsEmail-wrapper input-field flex flex-wrap items-start gap-2">
      <input 
        autocomplete="one-time-code"
        readonly
        onfocus="this.readOnly = false"
        type="email" 
        id="inviteGuest" 
        name="inviteGuest"
        class="input-field flex-grow"
        onblur="handleGuestEmailBlur(event)"
        onkeyup="handleGuestEmailKeyUp(event, this)">
    </div>
  `;

  // Replace button with input field
  target.parentElement.replaceWith(emailWrapper);

  // // Get reference to newly added input field
  // const inviteGuestInput = emailWrapper.querySelector("#inviteGuest");

  // // Handle "Enter" key event on the input field
  // inviteGuestInput.addEventListener("keyup", (event) => {
  //   if (event.key === "Enter") {
  //     handleGuestEmailBlur(event);
  //   }
  // });
};

window.handleGuestEmailKeyUp = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
    handleGuestEmailBlur(event);
  }
}

window.handleGuestEmailBlur = (event) => {
  const { target, key } = event;
  const { APOtype, guestEmails, APO_selectedData } = APOData;
  const wrapper = target.closest(".guestsEmail-wrapper");

  // Define validation schemas for guests email
  const validationSchema = {
    inviteGuest: [{ type: "isEmail" }],
  };

  target.readOnly = (key !== "Enter");

  const { name, value } = target;
  // Prevent empty input
  if (!value.trim()) {
    // Clear previous error messages for this field
    const existingError = wrapper.parentElement.querySelector(".has-error");
    if (existingError) existingError.remove();
    return;
  } 

  const isClassOrGlobalEvents = APOtype === "GlobalEvents" || APOtype === "Class"
  const guestMaxlength = isClassOrGlobalEvents ? APO_selectedData.bookSlotQuantity : 10;

  const { isValid } = validateField(name, value, validationSchema);
  const isEmailDuplicate = guestEmails.some((item) => item.emailAddress === value);
  const errorMessage = isEmailDuplicate
    ? "Email address already exist in guest list."
    : guestEmails.length >= guestMaxlength ? "Add guest limit completed" : !isValid
    ? "Please enter a valid email address."
    : "";

  showValidationMessages(wrapper, errorMessage);
  if (!isValid || isEmailDuplicate || guestEmails.length >= guestMaxlength) return;

  const uid = uuidv4();

  // Create email wrapper div
  const guestEmailDiv = document.createElement("div");
  guestEmailDiv.className =
    "guest-valid-email flex items-center flex-shrink max-w-full rounded";
  guestEmailDiv.innerHTML = `<div class="py-2 pl-2">${value}</div><i class="fa-solid fa-xmark p-2 cursor-pointer" data-id="${uid}" onclick="removeGuestEmail(event)" style="font-size: 14px;"></i>`;

  // Insert the new email div before the input field
  wrapper.insertBefore(guestEmailDiv, target);

  guestEmails.push({ id: uid, emailAddress: value });

  // Clear input value
  target.value = "";
};

window.removeGuestEmail = (event) => {
  const { target } = event;
  const { guestEmails } = APOData;
  const { id } = target.dataset;

  // Remove guest from the list
  APOData.guestEmails = guestEmails.filter((item) => item.id !== id);

  // Remove element from the DOM
  target.closest(".guest-valid-email")?.remove();
};

export const bookingForm_EventListeners = () => {
  const { CountriesList } = APOData;

  const bookingContactForm = document.getElementById("bookingContactForm");
  const APO__bookingBtn = bookingContactForm.querySelector("#bookingContactFormBtn");

  // Define validation schemas for each form
  const validationSchema = {
    name: [{ type: "isRequired" }],
    email: [{ type: "isRequired" }, { type: "isEmail" }],
    phoneNumber: [{ type: "isRequired" }, { type: "isMobilePhone", locale: "any" }],
    country_code: [
      { type: "isRequired" },
      { type: "isIn", options: CountriesList.map((list) => list.phone_code) },
    ],
    country: [
      { type: "isRequired" },
      { type: "isIn", options: CountriesList.map((list) => list.name) },
    ],
  };

  // Common event handler function
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    const { isValid, errorMessage } = validateField(
      name,
      value,
      validationSchema
    );
    showValidationMessages(event.target, errorMessage);
    toggleSubmitButton(bookingContactForm, validationSchema, APO__bookingBtn);
  };

  // Attach event listeners to form fields
  bookingContactForm.addEventListener("keyup", handleFieldChange);
  $(".jqueryCustomSelect").on("change", handleFieldChange);
};
