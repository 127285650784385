import { templateJSON } from "./BuilderProps";
import { getUrlParams, updateHandlebarsTemp } from "./CommonMethod";
import { APOData } from "./BuilderProps";
import { getServiceNextStep, Set__APOtype } from "./Serivceconfig";
import { get__ServiceEventClass } from "./Serivce";
import { displayTeamList } from "./Teams";

const NextStep = async (steps, isSingleStaffResult) => {
  // Destructure necessary data from APOData and templateJSON
  const { APOtype, Bookingrules } = APOData;

  // Get index of the "Datetime" step and the skip rule for team members
  const indexOfDatetime = steps.findIndex((step) =>
    step.name.includes("Datetime")
  );
  const isSkipTeamMembers = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsSkipTeamMembers"
  )?.isSelected;

  let nextStep = null;

  switch (APOtype) {
    case "Event":
      nextStep = "UserDetails";
      break;
    case "Class":
      nextStep = steps[indexOfDatetime].name;
      break;
    default:
      const { nextStep: serviceNextStep } = await getServiceNextStep(true);
      nextStep =
        (isSkipTeamMembers || isSingleStaffResult) &&
        serviceNextStep === "stafflist"
          ? steps[indexOfDatetime].name
          : serviceNextStep;
      break;
  }
  return nextStep;
};

export const appointmentLayout = async (result) => {
  const data = result ? result : await get__ServiceEventClass();
  //if (data.length) {
    // Destructure necessary data from APOData and templateJSON
    const { appointmentFilter, onboardingData, Bookingrules } = APOData;
    const { layout, steps } = templateJSON.StyleObj["Service"];

    // Check if the "IsServicesVisible" rule is selected
    const IsBookAppointmentVisible = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsBookAppointmentVisible")?.isSelected;

    // Get the target element and template & Handlebars template
    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${layout}`).innerHTML;

    const isSingleStaffResult = await getUrlParams("staff");

    // Prepare the data for the template
    const templateData = {
      appointmentData: data,
      step: await NextStep(steps, isSingleStaffResult),
      staffData: {
        singleStaff: isSingleStaffResult ? onboardingData.staffMembersList[0] : [],
        staffSelected: isSingleStaffResult ? false : true,
      },
      appointmentFilter,
      IsBookAppointmentVisible,
    };

    // Compile and update the Handlebars template
    await updateHandlebarsTemp(appendElement, template, templateData);

    // Apply custom select functionality
    $(".S_customSelect").customSelect();
  //}
  // display team list
  displayTeamList();
};

export const appointmentTypeList = async (data) => {
  try {
  // Destructure necessary data from APOData and templateJSON
  const { Bookingrules } = APOData;

  // Check if the "IsServicesVisible" rule is selected
  const IsServicesVisible = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsServicesVisible"
  )?.isSelected;

    // Update appointment filter based on provided conditions
    const APOtypeList = [
      { condition: data.isServiceAdded && IsServicesVisible, typeName: "Service" },
      { condition: data.isEventAdded, typeName: "Event" },
      { condition: data.isClassAdded, typeName: "Class" },
    ];

    // Apply the filtered results to APOData.appointmentFilter
    APOData.appointmentFilter = APOtypeList.filter((item) => item.condition).map((item) => item.typeName);

     // Set APOtype asynchronously
    APOData.APOtype = await Set__APOtype();
  } catch (error) {
    console.error("Error setting appointment types:", error);
  }
}
