import { GetAPI_deleteAPI_method } from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { initdatepicker } from "./Calendar";
import { fetchCountriesList, fetchCustomerList, updateHandlebarsTemp } from "./CommonMethod";
import { getServiceNextStep } from "./Serivceconfig";
import { bookingForm_EventListeners } from "./SignupFrom";
import { showPaymentMode } from "./PaymentDetail";
import { APO__DataUpdate } from "./OnboardingData";
import { appointmentLayout } from "./ServiceFilterHtml";
import { fetchProviderCountData } from "./SingleStaff";
import "jquery-custom-select";
import { bindLocationLayout } from "./MultiLocation";

window.filterAppointment = async (event) => {
  const { target } = event;

  // Remove "selected" class from all <li> elements
  document.querySelectorAll(".appointmentFilter li").forEach((li) => {
    li.classList.remove("selected");
  });

  // Add "selected" class to the clicked element
  target.classList.add("selected");

  // Update the filter data
  const { filter } = target.dataset;
  templateJSON.StyleObj["Service"].APOtype = filter;
  APOData.APOtype = filter;

  if (APOData.APO_selectedData.serviceActiveStep === "servicelist") {
    await appointmentLayout();
  }
  else {
    await bindLocationLayout();
  }

};

export const get__ServiceEventClass = async () => {
  try {
    
    //update APOtype if Appointment
    if (APOData.APOtype === "Appointment") {
        APOData.APOtype = "Service";
    }

    let { APOtype, onboardingData, appointmentFilter } = APOData;
    const matchingType = appointmentFilter.find(items => items.typeName === APOtype);

    let data = [];
    
    // Fetch data if the type is not "Service" and the data is not already loaded
    switch (APOtype) {
      case "Service":
        case "Appointment":
          data = onboardingData.serviceMenusList;
        break;
        // case "Event":
        //   // create new array with new perms for Event from data 
        //   const defaultHoursList = [];
        //   onboardingData.defaultHoursList.forEach(item => {
        //     if (item.isDisabled) {
        //       defaultHoursList.push({
        //         id: item.time,
        //         duration: item.time,
        //         isDisabled: item.isDisabled,
        //         title: item.name,
        //       });
        //     }
        //   });
        //   data = defaultHoursList;
        // break;
        case "Class":
        case "GlobalEvents":
          const endPoint= APOtype === "GlobalEvents" ? "Events" : matchingType.displayName;
          const response = await GetAPI_deleteAPI_method(endPoint, "GET");
          data = await addEventLocationTypeParam(response.data, APOtype);
    }

    // Update and return the data for the given APOtype
    APOData[APOtype] = await APO__DataUpdate(data, APOtype);
    return APOData[APOtype];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
};

const addEventLocationTypeParam = async (data, APOtype) => {
  return data.map((item) => ({
    ...item,
    usedSlots: APOtype === "Class" ? item.usedSeats : item.usedSlots,
    displayLocationType:
      item.locationType === "OnlinePhysical"
        ? [
            { location: item.location, locationType: "Online" },
            { location: "Inperson", locationType: "Physical" },
          ]
        : [
            {
              location: item.locationType === "Physical" ? "Inperson" : item.location,
              locationType: item.locationType
            },
          ],
  }));
};


export const APO__chooseService = async () => {
  const { APOtype } = APOData;
  switch (APOtype) {
    case "Service":
    case "Appointment":
      await Staff_Calendar(); // Proceed to select appointment step
      break;
    case "Class":
    case "GlobalEvents":
      APO__UserDetails();
      break;
    // default:
    //   APO__chooseDateTime();
  }
};

const Staff_Calendar = async () => {
  try {
    const { onboardingData, APO_selectedData, Bookingrules, selectedLocation, slugType } = APOData;
    const { serviceQParams } = APO_selectedData;
    const { staffMembersList } = onboardingData;

    // Determine the next step based on current service query parameters 
    const isStaffListStep = serviceQParams.step === "stafflist";
    const checkParamsbyStep = isStaffListStep || serviceQParams.slot;
    const { nextStep } = await getServiceNextStep(checkParamsbyStep);

    // Select booking content element and template
    const bookingContentElement = document.querySelector(".APO_booking_content");
    const template = document.getElementById(`template_${serviceQParams.step}`).innerHTML;

    // Extract booking rules
    const getBookingRule = (key) =>
      Bookingrules.bookingRulesLists.find((rule) => rule.keyName === key)?.isSelected;
    const IsSkipTeamMembers = getBookingRule("IsSkipTeamMembers");
    const IsAssignAnyTeamMember = getBookingRule("IsAssignAnyTeamMember");

    //filter isMemberCalenderShow only from staff list
    const filteredVisibleStaff = staffMembersList.filter(
      (member) => member.isMemberCalenderShow
    );

    // Prepare data for the Handlebars template
    const templateData = {
      ...APO_selectedData,
      serviceQParams,
      stafflist: staffMembersList,
      step: nextStep,
      IsSkipTeamMembers,
      selectedLocation,
      IsAssignAnyTeamMember: filteredVisibleStaff.length <= 1 ? false : filteredVisibleStaff,
    };

    // Utility function to assign data
    const assignData = (firstProvider) => {
      const staffID = firstProvider?.id || null;
      const staffJSON = firstProvider || null;
      Object.assign(APO_selectedData, { staffID, staffJSON });
      Object.assign(serviceQParams, { staff: staffID });
      Object.assign(templateData, { staffID, staffJSON });
    };

    // if IsAssignAnyTeamMember and IsSkipTeamMembers rule true
    // if (IsAssignAnyTeamMember && serviceQParams.step === "stafflist" & (serviceQParams.staffSelected === null || JSON.parse(serviceQParams.staffSelected))) {
    if (filteredVisibleStaff.length > 1) {
      if (((IsAssignAnyTeamMember && isStaffListStep) || IsSkipTeamMembers) && slugType !== "team") {
        const [firstProvider] = await fetchProviderCountData(selectedLocation.id);
        if(IsSkipTeamMembers) {
          // Auto-assign the first provider
          assignData(firstProvider);
          
        } 
        else {
          // Clear staff selection
          assignData(null);
          templateData.assignAnyStaff = firstProvider;
        }
      }
    }
    else {
      assignData(filteredVisibleStaff[0]);
    }

    // Update the template with the prepared data
    await updateHandlebarsTemp(bookingContentElement, template, templateData);

    // Initialize the date picker for the "datetime" step 
    if (["datetime"].includes(serviceQParams.step)) {
      initdatepicker(); // Initialize calendar
    }
  } catch (error) {
    console.error("Error in Staff_Calendar:", error);
  }
};

export const APO__UserDetails = async () => {
  // Select the booking content element
  const bookingContentElement = document.querySelector(".APO_booking_content");

  // Destructure necessary properties from StyleObj and APOData
  const { Signup, Service } = templateJSON.StyleObj;
  const { APOtype, APO_selectedData, CountriesList, onboardingData, selectedLocation } = APOData;
  const { appointmentJSON } = APO_selectedData;

  // Check if CountriesList is empty and populate it if needed
  if (CountriesList.length === 0) {
    const placeholderTemplate = document.getElementById(
      `template_UserD-placeholderLoading`
    ).innerHTML; // Get Handlebars template

    await fetchCountriesList(bookingContentElement, placeholderTemplate);
  }

  // Check if AllcustomerID is not set and populate it if needed
  if (!APOData.AllcustomerID) {
    await fetchCustomerList();
  }

  // Retrieve the last step template from the Service object
  const template = document.getElementById(`template_${Service.steps.slice(-1)[0].name}`).innerHTML; // Get Handlebars template

  
  if (APOtype === "GlobalEvents" || APOtype === "Class") {
    Object.assign(APO_selectedData, {
      bookSlotQuantity:  !appointmentJSON.usedSlots ? 0 : 1,
      allowMaxEventSlots: appointmentJSON.usedSlots <= 10 ? appointmentJSON.usedSlots : 10,
    });

    // Store unique guest email IDs globally
    APOData.guestEmails = [];

  }
  else {
    // Function to remove fields with "inviteGuest" and "SlotsQuantity"
    Signup.form.fields.forEach(field => {
      field.row = field.row.filter(item => item.feildref !== "inviteGuest" && item.feildref !== "SlotsQuantity");
    });
  }

  // Prepare the order detail object
  const orderDetail = {
    ...APO_selectedData,
    CountriesList,
    selectedLocation,
    form: Signup.form,
    UserCountries: onboardingData.userCountry,
    
  };

  // Update the booking content with the Handlebars template and order details
  await updateHandlebarsTemp(bookingContentElement, template, orderDetail);

  // Initialize custom select elements with search functionality
  $(".DropwithSearch").customSelect({ search: true });

  // Attach event listeners for the booking form
  bookingForm_EventListeners();
};

window.submitBookingContactForm = async (event, element) => {
  event.preventDefault(); // Prevent default form submission

  // Find the form element (assuming button is inside the form)
  const formEle = event.currentTarget.closest("#bookingContactForm");
  if (!formEle) {
    console.error("Form not found");
    return;
  }
  const formData = new FormData(formEle);

  APOData.userDetail = {
    name: formData.get("name"),
    emailAddress: formData.get("email"),
    phoneNumber: "+" + formData.get("country_code") + formData.get("phoneNumber"),
    country: formData.get("country"),
    listId: APOData.AllcustomerID,
  };

  // Select the booking content element
  const bookingContentElement = document.querySelector(".APO_booking_content");
  const placeholderTemplate = document.getElementById(
    `template_UserD-placeholderLoading`
  ).innerHTML; // Get Handlebars template
  await updateHandlebarsTemp(bookingContentElement, placeholderTemplate, "");

  showPaymentMode();
};

window.APO__bookanother = (event, element) => {
  if (window.location.hash) {
    // Remove the hash
    history.replaceState(
      null,
      null,
      window.location.pathname + window.location.search
    );
    location.reload(); // Refresh the page
  }
};

window.serviceBackStep = (event) => {
  event.preventDefault();
  window.history.back();
};
