import { APOData } from "./BuilderProps";
import { updateHandlebarsTemp, convertTimeToMinutes, businessTimeZoneToUserTimezone } from "./CommonMethod";
import { getServiceNextStep } from "./Serivceconfig";
import { GetAPI_deleteAPI_method } from "./Api";
import moment from "moment";
import { toZonedTime } from "date-fns-tz";
import { template_meetingSlots, template_Timeslot_Loading } from "./teamMeeting/MeetingHandlebars-template";
import {
  fetchStaffHoursByLocation,
} from "./SingleStaff";

export const fetchSelectedStaffData = async (staffId, locId) => {
  try {
    // Call the API to get events based on the staff ID
    const response = await GetAPI_deleteAPI_method(
      `EventsByStaffId?StaffId=${staffId}&LocationId=${locId}`,
      "GET"
    );

    // Validate the API response to ensure it contains data
    if (!response?.data || !response.data.length) {
      return [];
    }

    // Return the fetched booking list data
    return response.data;
  } catch (error) {
    console.error("Error fetching Selected Staff Data:", error);
  }
};

// Function to handle team data and apply relevant booking rules
const handleTeamDataAndRules = async () => {
  const { APO_selectedData, Bookingrules, selectedLocation } = APOData;
  // const { serviceQParams } = APO_selectedData;

  // // Filter for 'IsSkipTeamMembers' rule to determine if team members should be skipped
  // const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find(
  //   (rule) => rule.keyName === "IsSkipTeamMembers"
  // );

  // // If the rule is selected and no staff is specified, auto-select the first provider from the ProviderCount API
  // if (IsSkipTeamMembers.isSelected && !APO_selectedData.staffID) {
  //   try {
  //     const sortedProviders = await fetchProviderCountData(selectedLocation.id);
  //     // serviceQParams.staff = sortedProviders[0].id; // Select the first provider's ID
  //     APO_selectedData.staffID = sortedProviders[0].id;
  //     await fetchSingleStaffData(sortedProviders[0].id); //fetch staff data
  //     //}
  //   } catch (error) {
  //     console.error("Error fetching provider count data:", error);
  //   }
  // }

  const { staffJSON } = APOData.APO_selectedData;

  // Fetch and update the selected staff working HoursList as per Location id
  if (staffJSON) {
    staffJSON.businessHoursList = await fetchStaffHoursByLocation(
      staffJSON.id,
      selectedLocation.id
    );
  }
};

export const APO_previewDate = async (businessHours) => {
  // APOData.utcTime = await getUtcTime();
  const { businessTimeZone } = APOData;
  const selectedDay = toZonedTime(moment().format(), businessTimeZone);
  // Use Moment.js to work with the UTC time

  const today = moment(selectedDay).local().format("dddd");
  const todayIndex = businessHours.findIndex((day) => day.day === today);
  const dayMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  for (let i = 0; i < businessHours.length; i++) {
    const dayIndex = (todayIndex + i) % businessHours.length;
    const dayData = businessHours[dayIndex];

    if (dayData.isOpened) {
      const daysToAdd = (dayMap[dayData.day] - todayIndex + 7) % 7;
      // return moment(selectedDay).set("days", daysToAdd)
      return moment(selectedDay).clone().add(daysToAdd, "days");
    }
  }

  return null; // No open days found
};

// Function to initialize the date picker component
export const initdatepicker = async () => {
  let isInitialized = true; // Track initialization state

  // Fetch team data and apply rules before initializing the date picker
  await handleTeamDataAndRules();

  const { APO_selectedData, businessTimeZone } = APOData;
  const { businessHoursList } = APO_selectedData.staffJSON;

  // find the open day from provider business hours
  let openedDay = await APO_previewDate(businessHoursList);

  // Determine the initially selected day (default to tomorrow's date if not set)
  // let selectedDay = moment(new Date());
  // if (!!APO_selectedData.date) {
  //   openedDay = APO_selectedData.date;
  // } else {
  //   APO_selectedData.date = openedDay;
  // }

  APO_selectedData.date = openedDay;

  // Generate a comma-separated list of disabled days based on business hours
  const disabledDays = businessHoursList
    .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
    .filter((day) => day !== null)
    .join(",");

  // Initialize the date picker with the desired configuration
  $("#datepicker")
    .datepicker({
      // daysOfWeekDisabled: datesForDisable.replace(/,(\s+)?$/, ""),
      daysOfWeekDisabled: disabledDays,
      format: "mm-dd-yyyy",
      startDate: toZonedTime(moment().format(), businessTimeZone),
      weekStart: 1,
      todayHighlight: false,
      templates: {
        leftArrow: '<i class="fa fa-long-arrow-left"></i>',
        rightArrow: '<i class="fa fa-long-arrow-right"></i>',
      },
      beforeShowDay: function (date) {
        //return {
        //  content: `<span>${new Date(date).getDate()}</span>`
        //}
      },
    })
    .on("changeDate", async function (e) {
      // Update the selected day based on user input
      // const selectedDay = e.date;
      // APO_selectedData.date = moment(e.date).format();

      let selectedDay = new Date(e.date);
      let updateSelectedDay = moment();
      // Update the moment date with the new date
      updateSelectedDay
        .year(selectedDay.getFullYear())
        .month(selectedDay.getMonth())
        .date(selectedDay.getDate());

      APO_selectedData.date = moment(updateSelectedDay).format();

      // Remove the 'today' class from all days in the date picker to avoid styling conflicts
      document
        .querySelectorAll(".datepicker-days .day")
        .forEach((day) => day.classList.remove("today"));

      // If not initializing, reapply team data and rules
      if (!isInitialized) {
        await handleTeamDataAndRules();
      } else {
        isInitialized = false; // Mark as not initializing after the first change
      }

      // Update service duration based on the newly selected day
      serviceDuration(updateSelectedDay);
    });

  // Set the date picker to display the initially selected day
  if (openedDay) {
    $("#datepicker").datepicker("setDate", moment(openedDay).local().format("l"));
  }
};

// Function to check service duration
export const serviceDuration = (selectedDay) => {
  const { staffJSON, appointmentJSON } = APOData.APO_selectedData;

  // Get day name from selectedDay (e.g., "Monday")
  // const dayName = daysOfWeek[new Date(selectedDay).getDay()];
  const dayName = selectedDay.format("dddd");

  // Find business hours for the given day
  const businessHours = staffJSON.businessHoursList.find(
    (item) => item.day === dayName
  );

  // Return early if no business hours are found or if the business is closed
  if (!businessHours || !businessHours.isOpened) return;

  // Destructure business hours properties
  const { from, to, breakHours } = businessHours;

  // Format start and end times in "HH:mm" format
  // const startTime = moment(from).format("HH:mm");
  // const endTime = moment(to).format("HH:mm");

  // Convert the appointment duration to minutes
  const durationInMinutes = convertTimeToMinutes(appointmentJSON.duration);
  const timeData = {
    from,
    to,
    duration: durationInMinutes,
    breakHours,
  };

  // Generate service time slots for the given day using time data
  generateServiceTimeSlots(timeData, selectedDay);
};

// Function to calculate service time slots
const generateServiceTimeSlots = async (timeData, selectedDay) => {
  // Destructure necessary data from APOData
  const {
    APO_selectedData,
    slugType,
    selectedLocation,
    businessTimeZone
  } = APOData;
  const { serviceQParams, staffJSON } = APO_selectedData;

  const { from, to, duration, breakHours } = timeData;

  // Select the DOM element where the appointment slots will be rendered
  const SlotEle = document.querySelector(".AppointmentSlot");

  if (slugType === "meeting") {
    // Compile and update the Handlebars template with the prepared data
    await updateHandlebarsTemp(
      SlotEle,
      template_Timeslot_Loading(),
      ""
    );
  }

  const isToday = moment().format("DD") === moment(selectedDay).format("DD");
  const isTodayDate = moment().format("l") <= moment(selectedDay).format("l");
  const currentTime = moment().format("HH:mm");
  // const isStartTimePassed = currentTime >= from;

  const availableServiceSlots = [];
  let bookedSlotsAndBreakHours = [];
  // let bookedSlots = [];

  // Fetch selected staff booking list
  const selectedStaffBookingList = await fetchSelectedStaffData(
    staffJSON.id,
    selectedLocation.id
  );

  if (selectedStaffBookingList.length > 0) {
    // Filter bookings to include only those that match the selected day
    // const filteredBookings = selectedStaffBookingList.filter(
    //   booking => booking?.startDate && moment(booking.startDate).isSame(selectedDay, "day")
    // );

    const filteredBookings = selectedStaffBookingList.filter(
      (booking) =>
        booking?.startDate &&
        moment(booking.startDate).format("l") == moment(selectedDay).format("l")
    );

    // Map the filtered bookings to an array of objects with 'from' and 'to' times
    const bookedSlots = filteredBookings.map((booking) => ({
      from: booking.startDate,
      to: booking.endDate,
      bookedManual: true,
    }));

    // Combine booked slots with break hours
    bookedSlotsAndBreakHours = [...bookedSlots, ...breakHours];
  }

  // Initialize timeSlot with the start time, and iterate until it reaches the end time
  let timeSlot = moment(from, "HH:mm");
  const endMoment = moment(to, "HH:mm");

  // if (slugType === "meeting") {
  //   // Define the time zones
  //   const momentCurrentTimezone = moment.tz(localpInfo.timezone);
  //   const momentStaffTimezone = moment.tz(businessTimeZone);

  //   // Calculate the absolute time difference utcOffsetDiffInMinutes
  //   const utcOffsetDiffInMinutes =
  //     momentStaffTimezone.utcOffset() - momentCurrentTimezone.utcOffset();
  //   const timeDifferenceInHours = Math.floor(
  //     Math.abs(utcOffsetDiffInMinutes) / 60
  //   );
  //   const remainingMinutes = Math.abs(utcOffsetDiffInMinutes % 60);

  //   // Display the results
  //   console.log(
  //     `Time difference: ${timeDifferenceInHours} hours and ${remainingMinutes} minutes ${
  //       utcOffsetDiffInMinutes < 0 ? "behind" : "ahead"
  //     }`
  //   );
  // }

  while (timeSlot.isBefore(endMoment)) {
    const slotTime = timeSlot.format("HH:mm");

    // Check if the current slot falls within a break or booked slot
    const { isBreak, breakEndTime } = await isWithinBreakTime(
      slotTime,
      bookedSlotsAndBreakHours
    );

    // Add the slot to available slots if it's either not today or after the current time
    if (!isToday || !isTodayDate || currentTime < slotTime) {
      const fullTimestamp = moment(selectedDay).set({
        hour: timeSlot.hour(),
        minute: timeSlot.minute(),
      });

      // Convert time slots from business timezone into user timezone
      const convertToUserTimezone = (slugType === "meeting") ? await businessTimeZoneToUserTimezone(slotTime) : "00:00";

      availableServiceSlots.push({
        slot: slotTime,
        timestamp: fullTimestamp.unix(),
        break: isBreak,
        convertToUserTimezone,
      });
    }

    // If the slot is within a break, skip to the end of the break; otherwise, increment by duration
    // timeSlot = isBreak
    //   ? moment(breakEndTime, "HH:mm")
    //   : timeSlot.clone().add(duration, "minutes");

    timeSlot = timeSlot.clone().add("15", "minutes");
  }

  if (slugType === "meeting") {
    const meetingStolsData = {
      Bookingslots: availableServiceSlots,
      staffJSON,
    };

    // Compile and update the Handlebars template with the prepared data
    await updateHandlebarsTemp(
      SlotEle,
      template_meetingSlots(),
      meetingStolsData
    );
  } else {
    // Determine the next step based on the service step and staff availability
    const checkParamsbyStep = serviceQParams.step === "datetime" ? true : staffJSON;
    const { nextStep } = await getServiceNextStep(checkParamsbyStep); // Determine the next step based on staff availability

    // Prepare data for the Handlebars template slugType
    const templateData = {
      Bookingslots: availableServiceSlots.filter((slot) => !slot.break), // Available service slots data
      serviceQParams,
      step: nextStep,
      staffJSON,
    };

    // Get the Handlebars template from the script element
    const template = document.getElementById(
      `template_AvailableSlot`
    ).innerHTML;

    // Compile and update the Handlebars template with the prepared data
    await updateHandlebarsTemp(SlotEle, template, templateData);
  }
};

// Function to check if the time is within break hours
const isWithinBreakTime = async (time, breakHours) => {
  if (!breakHours || breakHours.length === 0)
    return { isBreak: false, breakEndTime: null };
  let breakInfo = { isBreak: false, breakEndTime: null };

  // Iterate through break times and find if the time falls within any break period
  breakHours.forEach((breakTime) => {
    const {from, to, bookedManual} = breakTime
    const breakStartTime = bookedManual ? moment(from).format("HH:mm") : from;
    const breakEndTime = bookedManual ? moment(to).format("HH:mm") : to;

    if (time >= breakStartTime && time < breakEndTime) {
      breakInfo = { isBreak: true, breakEndTime: breakEndTime };
    }
    // return time >= breakStartTime && time < breakEndTime;
  });
  return breakInfo;
};
